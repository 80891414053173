import React, { Suspense, lazy, useEffect } from 'react';
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';

// admin
const DetailMenu = lazy(() => import('../pages/dashboard/menu/DetailMenu'));
const ActionMenu = lazy(() => import('../pages/dashboard/menu/ActionMenu'));
const DetailPages = lazy(() => import('../pages/dashboard/page/DetailPages'));
const DetailSectionPages = lazy(() =>
  import('../pages/dashboard/page/DetailSectionPages')
);
const ActionSectionPages = lazy(() =>
  import('../pages/dashboard/page/ActionSectionPages')
);
const ActionPages = lazy(() => import('../pages/dashboard/page/ActionPages'));

// other***
const LayoutApp = lazy(() => import('../pages/LayoutApp'));
const Login = lazy(() => import('../pages/auth/Login'));
const Ui = lazy(() => import('../pages/Ui'));
const NotFound = lazy(() => import('../pages/NotFound'));

const Routers = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('token')) {
      if (window.location.pathname === '/') {
        navigate('/dashboard/page');
      }
    } else {
      navigate('/');
    }
  }, []);

  return (
    <Routes>
      <Route path='/dashboard/'>
        <Route
          element={
            <LayoutApp>
              <Outlet />
            </LayoutApp>
          }
        >
          <Route path='page/'>
            <Route
              index
              element={
                <Suspense>
                  <DetailPages />
                </Suspense>
              }
            />
            <Route
              path='create/'
              element={
                <Suspense>
                  <ActionPages />
                </Suspense>
              }
            />
            <Route
              path='edit/:id'
              element={
                <Suspense>
                  <ActionPages />
                </Suspense>
              }
            />
            <Route
              path='section/:id'
              element={
                <Suspense>
                  <DetailSectionPages />
                </Suspense>
              }
            />
            <Route
              path='section/:id/:section_id'
              element={
                <Suspense>
                  <ActionSectionPages />
                </Suspense>
              }
            />
          </Route>
          <Route path='menu/'>
            <Route
              index
              element={
                <Suspense>
                  <DetailMenu />
                </Suspense>
              }
            />
            <Route
              path='createmain/'
              element={
                <Suspense>
                  <ActionMenu />
                </Suspense>
              }
            />
            <Route
              path='editmain/:id'
              element={
                <Suspense>
                  <ActionMenu />
                </Suspense>
              }
            />
            <Route
              path='createsub/:id'
              element={
                <Suspense>
                  <ActionMenu />
                </Suspense>
              }
            />
            <Route
              path='editsub/:id'
              element={
                <Suspense>
                  <ActionMenu />
                </Suspense>
              }
            />
          </Route>
        </Route>
      </Route>

      <Route path='/'>
        <Route
          index
          element={
            <Suspense>
              <Login />
            </Suspense>
          }
        />
      </Route>

      <Route
        path='/ui'
        element={
          <Suspense>
            <Ui />
          </Suspense>
        }
      />
      <Route
        path='*'
        element={
          <Suspense>
            <NotFound />
          </Suspense>
        }
      />
    </Routes>
  );
};
export default Routers;
