const INITIAL_STATE = {
  loading: false,
  user: {},
  theme: localStorage.getItem("theme") || "light",
};

const reducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        loading: action?.payload,
      };
    case "SET_USER":
      return {
        ...state,
        user: action?.payload,
      };
    case "SET_THEME":
      return {
        ...state,
        theme: action?.payload,
      };
    default:
      return state;
  }
};
export default reducers;
